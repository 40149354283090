import React from "react"
import Seo from "../../components/Seo/Seo"
import * as styles from  "./community.module.sass"
import PageTransition from "../../components/PageTransition";

const CommunityPage = (props) => (

	<PageTransition {...props}>

		<Seo title="Interesting" keywords={[]} />

		<div className={styles.communityPage}>

			<header>

				<h1 id="main-title"><span>Deviant Robot Community</span>Main Monthly Monday Meeting</h1>

			</header>

			<article>

				<div className={styles.Page}>

					<p>The Main Monthly Meeting is a <strong>SPECIAL MONTHLY EVENT</strong> designed to bring the community together and through a shared experience. It's an excuse for us all to hangout and take part in something fun together.</p>

					<h3>Want to talk at our event?</h3>

					<p>If you would like participate, <a href="https://forms.gle/8X2FQjRpjqweUSN88">please submit your idea for a community slot via this Google form here.</a></p>

					<h3>Agenda</h3>

					<p>We aim for it to last around 60-90min and the format would look something like this: -</p>

					<h4><em>5min</em> - Welcome</h4>
					<p>Saying hello to everyone, and introducing the format.</p>

					<h4><em>5min</em> - Server announcements</h4>
					<p>Letting everyone know about upcoming events, server changes, club activities etc</p>

					<h4><em>30min</em> - Community Slots - talks, chats or questions</h4>

					<p>Community members would have <strong>5min</strong> to do one of three things:</p>

					<ul>
						<li><strong>Give a brief talk</strong> on an interesting experience they have had or on a topic they think will interest the community.</li>
						<li><strong>Stage chat</strong> For those less confident about talking for 5min to an audience; you could introduce a topic and have a conversation with the stage moderator.</li>
						<li><strong>Ask a question of the audience</strong> If you have been trying to understand something, always wanted to know something or have just had an odd question occur to you don’t know where to ask it.. well this might be your chance. Questions should be fun and or interesting... no one wants to know about your rash and you should probably not be getting advice about it from anyone here :slight_smile:</li>
					</ul>

					<p>If you would like participate, <a href="https://forms.gle/8X2FQjRpjqweUSN88">please submit your idea for a community slot via this Google form here.</a></p>

					<h4><em>10min</em> - Community Q&A</h4>
					<p>Opportunity for the community to raise questions for the project team.</p>

					<h4><em>20min</em> - Fun Finale</h4>

					<p>We would round the event off with a fun game. Candidates include: -</p>

					<ul>
						<li>Quiplash</li>
						<li>PowerPoint karaoke (which I am assured is a thing)</li>
						<li>Other online games</li>
					</ul>

				</div>

			</article>

		</div>

	</PageTransition>

)

export default CommunityPage
